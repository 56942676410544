import { resolveTokens } from '@solid-primitives/jsx-tokenizer';
import { Breadcrumbs, BreadcrumbTokenizer, Container, Picture } from '@troon/ui';
import { createEffect, mergeProps, Show, splitProps } from 'solid-js';
import { twJoin } from '@troon/tailwind-preset/merge';
import { useWindowScrollPosition } from '@solid-primitives/scroll';
import { ImageOverlay } from '../image-overlay';
import type { Component, ComponentProps, ParentProps } from 'solid-js';

type Props = ParentProps<{
	breadcrumbs?: Component;
	darker?: boolean;
	fullWidth?: boolean;
	src?: string | null;
	alt?: string;
	size?: 'default' | 'large' | 'small';
}> &
	Partial<ComponentProps<typeof Picture>>;

export function Hero(inputProps: Props) {
	const props = mergeProps({ size: 'default' }, inputProps);
	const [, img] = splitProps(props, ['src', 'alt', 'size', 'children', 'breadcrumbs']);
	const scroll = useWindowScrollPosition();

	let pic: HTMLImageElement;
	createEffect(() => {
		pic!.style.transform = `scale(${Math.max(scroll.y / window.innerWidth + 1, 1)})`;
	});

	return (
		<div
			class={twJoin(
				'relative mb-12 flex flex-col items-stretch overflow-hidden bg-gradient-to-r from-neutral-950 to-brand-700',
				props.size === 'small' ? 'min-h-24' : 'sm:min-h-72 md:min-h-96',
				props.breadcrumbs && props.size !== 'small' ? 'min-h-96' : props.size !== 'small' ? 'min-h-64' : '',
			)}
		>
			<Picture
				preload
				ref={pic!}
				crop="center"
				{...(img as ComponentProps<typeof Picture>)}
				src={props.src!}
				alt={props.alt ?? ''}
				width={1280}
				height={props.size === 'small' ? 100 : props.size === 'large' ? 819 : 388}
				sizes="100vw"
				// eslint-disable-next-line tailwindcss/no-arbitrary-value
				class={twJoin('absolute size-full bg-center object-cover', props.darker ? 'brightness-[70%]' : 'brightness-75')}
				loading="eager"
				draggable={false}
			/>
			<Show when={props.breadcrumbs}>
				{(crumbs) => {
					const Crumbs = crumbs();
					return (
						<HeroBreadcrumbs>
							<Crumbs />
						</HeroBreadcrumbs>
					);
				}}
			</Show>

			<div
				class={twJoin(
					'z-10 mx-auto flex h-full grow flex-col items-center justify-center gap-4 px-4 text-center text-white',
					props.size !== 'small' && 'my-16 pt-16',
					props.fullWidth ? 'w-full' : 'max-w-3xl',
				)}
			>
				{props.children}
			</div>
			<ImageOverlay class="relative top-1" />
		</div>
	);
}

function HeroBreadcrumbs(props: ParentProps) {
	const tokens = resolveTokens(BreadcrumbTokenizer, () => props.children);
	return (
		<Container class="z-20 -mb-20 mt-20 text-white">
			<Breadcrumbs appearance="current">{tokens()}</Breadcrumbs>
		</Container>
	);
}
